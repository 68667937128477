import {
  Collapse,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  HeadDataBaseProps,
  BodyDataBaseProps,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import { NumericFormat } from "../../components/NumericFormat";
import { Search } from "../../components/Search";
import api from "../../services/Api";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import "../../style.css";
import nfApiHelper from "../../utils/nfApiHelper";
import { BsVariant } from "../../types/BsVariant";
import { IssueErrors } from "../../types/IssueErrors";
import { useNf } from "../../hooks/nf";
import useBackendLoad from "../../hooks/backendReload";
import { ModalValidateNf } from "../../components/ModalValidateNf";
import useSendEmailAndWhatsapp from "../../hooks/sendEmailAndWhatsapp";
import EmailNfService from "../../services/EmailNfService";
import WhatsappNfService from "../../services/WhatsappNfService";
import NfceService from "../../services/NfceService";
import ModalSuccess from "../../components/ModalSuccess";
import { ModalSendEmail } from "../../components/ModalSendEmail";
import { ModalSendWhatsapp } from "../../components/ModalSendWhatsapp";
import { ModalNfDetails } from "../../components/ModalNfDetails";
import useNfceDetails from "../../hooks/nfceDetails";
import CustomerService from "../../services/CustomerService";
import ModalLimit from "../../components/ModalLimit";
import { NfApiSituation } from "../../types/NfApiSituation";
import NfeSelectMonthModal from "../../components/NfeSelectMonthModal";

type Filters = {
  searchQuery: string;
  situation: string;
  valueMin: number;
  valueMax: number;
  createdDateMin: string;
  createdDateMax: string;
};

type Invoices = {
  rps: number;
  id: number;
  date: string;
  customer: number;
  customerName?: string;
  totalValue: number;
  situation: string;
};

export function ListNfce() {
  const { user } = useSelector((state: any) => state.auth);

  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

  const [all, setAll] = useState<any[]>([]);
  const [countTotalNf, setCountTotalNf] = useState(0);
  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();
  const { nfceEnabled } = useNf();

  const [nfceToIssue, setNfceToIssue] = useState<any | undefined>();
  const [showModalIssue, setShowModalIssue] = useState(false);

  const {
    showModalDetails,
    setShowModalDetails,
    cancelNote,
    setCancelNote,
    cancelReason,
    setCancelReason,
    nfceDetails,
    setNfceDetails,
    cancelError,
    setCancelError,
    cancelNfce,
    getNfe,
  } = useNfceDetails();

  const [showModalInfo, setShowModalInfo] = useState(false);
  const [msgInfo, setMsgInfo] = useState("");
  const [issueErrors, setIssueErrors] = useState<IssueErrors[]>([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [situation, setSituation] = useState("");
  const [valueMin, setValueMin] = useState(0);
  const [valueMax, setValueMax] = useState(0);
  const [createdDateMin, setCreatedDateMin] = useState("");
  const [createdDateMax, setCreatedDateMax] = useState("");
  const filtersRef = useRef<Filters | null>(null);

  const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [selectedNfe, setSelectedNfe] = useState<any>([]);
  const [showNfeModal, setShowNfeModal] = useState(false);
  const [sentNfce, setSentNfce] = useState(0);

  //Envio de Email/Whatspp
  const {
    showModalEmail,
    setShowModalEmail,
    infoEmail,
    setInfoEmail,
    emailsHistory,
    setEmailsHistory,
    showModalWhatsapp,
    setShowModalWhatsapp,
    infoWhatsapp,
    setInfoWhatsapp,
    whatsappHistory,
    setWhatsappHistory,
  } = useSendEmailAndWhatsapp();

  const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
  const [showModalLimit, setShowModalLimit] = useState(false);

  const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Ref" },
    { reference: "rps", value: "RPS", notSortable: true },
    { reference: "date", value: "Data" },
    { reference: "customer", value: "Cliente" },
    { reference: "totalValue", value: "Valor Total" },
    {
      reference: "situation",
      value: "Situação",
      situation: true,
      notSortable: true,
    },
  ];

  // useEffect(() => {
  //     handleClickSearch();
  // }, [all]);

  const loadData = useCallback(
    async ({
      rowsPerPage,
      currentPage,
      sortDirection,
      sortReference,
    }: LoadDataParams) => {
      const response = await api.get<{ rows: any[]; count: number }>("nfce", {
        params: {
          skip: rowsPerPage * currentPage,
          take: rowsPerPage,
          filters: filtersRef.current
            ? JSON.stringify(filtersRef.current)
            : undefined,
          sortReference,
          sortDirection,
          sentNfce,
        },
      });

      const { rows, count } = response.data;

      setAll(rows);
      setCountTotalNf(count);
    },
    []
  );

  useEffect(() => {
    getBodyData();
  }, [all]);

  useEffect(() => {
    if (!nfceEnabled) {
      setShowModalInfo(true);
      setMsgInfo(
        "Empresa não pode emitir NFC-e portanto essa funcionalidade foi desativada!"
      );
    }
  }, [nfceEnabled]);

  const displaySentNfce = async (val: number) => {
    const response = await api.get<{ rows: any[]; count: number }>("nfce", {
      params: {
        skip: 0,
        take: 15,
        filters: filtersRef.current
          ? JSON.stringify(filtersRef.current)
          : undefined,
        sortReference: "date",
        sortDirection: "DESC",
        sentNfce: val,
      },
    });

    const { rows, count } = response.data;
    setSentNfce(val);
    setAll(rows);
    setCountTotalNf(count);
    getBodyData(rows);
  };

  function convertDate(date: string) {
    var auxDate = date.split("-");
    return auxDate[2] + "/" + auxDate[1] + "/" + auxDate[0];
  }

  async function getBodyData(data: any = null) {
    const list: BodyDataBaseProps[][] = [];
    let aux = all;
    if (data) {
      aux = data;
    }

    for (const invoice of aux) {
      invoice.customerName = CustomerService.getCustomerName(
        invoice.customerEntity
      );

      const status =
        invoice.situation === "open"
          ? "Em aberto"
          : invoice.situation === "progress"
          ? "Em andamento"
          : invoice.situation === "attended"
          ? "Atendido"
          : invoice.situation === "canceled"
          ? "Cancelado"
          : invoice.situation === "aproved"
          ? "Aprovado"
          : "";
      let checkSelected: boolean = selectedNfe.includes(invoice.id);

      const data: BodyDataBaseProps[] = [
        { for: "id", value: String(invoice.id), id: true },
        { for: "rps", value: "" },
        { for: "date", value: convertDate(invoice.date) },
        { for: "customer", value: String(invoice.customerName ?? "-") },
        { for: "totalValue", value: formatCurrency(invoice.totalValue) },
        {
          for: "situation",
          value: nfApiHelper.getStatus(invoice.api_situation),
        },
        {
          for: "editButton",
          value: nfApiHelper.canEdit(invoice.api_situation) ? "y" : "n",
          hidden: true,
        },
      ];

      list.push(data);
    }
    setBodyData(list);
  }

  async function handleConfirmIssue() {
    setShowModalIssue(false);
    setIssueErrors([]);
    if (nfceToIssue) {
      try {
        const response = await api.post(`nfce/issue/${nfceToIssue.id}`);

        const aux = all;
        const index = aux.findIndex((obj) => obj.id === response.data.id);
        aux[index] = response.data;

        setAll([...aux]);

        setShowModalInfo(true);
        setMsgInfo("Nota fiscal enviada para processo de emissão com sucesso!");
      } catch (error) {
        console.log(error.response?.data);
        setShowModalInfo(true);
        setMsgInfo(error.response?.data?.message ?? "Erro ao emitir");
        if (error.response?.data?.errors) {
          setIssueErrors(error.response.data.errors);
        }
      }
    }
  }

  async function handleConfirmCancel() {
    const cancelResponse = await cancelNfce();

    if (!cancelResponse) return;

    const aux = all;
    const index = aux.findIndex((obj) => obj.id === cancelResponse.data.id);
    aux[index] = cancelResponse.data;

    setAll([...aux]);
  }

  const handleClickDelete = useCallback(
    async (id: string) => {
      try {
        const filtered = all.filter((cat) => cat.id !== Number(id));
        await api.delete(`nfce/${id}`);

        setAll([...filtered]);
      } catch (error) {
        console.log(error);
      }
    },
    [all]
  );

  const handleClickEdit = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/${id}`);
    },
    [pathname]
  );

  const handleClickClone = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/duplicar/${id}`);
    },
    [pathname]
  );

  const handleClickAdd = useCallback(() => {
    pushHistory(`${pathname}/adicionar`);
  }, [pathname]);

  const handleClickIssue = useCallback(
    async (id: string) => {
      const canIssue = await checkLimits();

      if (!canIssue) return;

      setShowModalIssue(true);
      setNfceToIssue(all.find((nfce) => nfce.id === Number(id)));
    },
    [all]
  );

  const issueButtonCondition = useCallback(
    (id: string) => {
      const row = all.find((obj) => String(obj.id) === id);

      if (!row) return false;

      return nfApiHelper.canIssue(row.api_situation) && nfceEnabled;
    },
    [all, nfceEnabled]
  );

  const handleClickDetails = useCallback(
    async (id: string) => {
      setShowModalDetails(true);
      setCancelNote(false);
      setCancelError("");
      await getNfe(id);
    },
    [getNfe]
  );

  const detailsButtonCondition = useCallback(
    (id: string) => {
      const row = all.find((obj) => String(obj.id) === id);

      if (!row) return false;

      return nfApiHelper.hasDetails(row.api_situation);
    },
    [all]
  );

  const clearSearch = () => {
    setSearchQuery("");
    setValueMin(0);
    setValueMax(0);
    setCreatedDateMin("");
    setCreatedDateMax("");
  };

  const handleClickSearch = useCallback(async () => {
    filtersRef.current = {
      searchQuery,
      situation,
      valueMin,
      valueMax,
      createdDateMin,
      createdDateMax,
    };

    reloadData();
  }, [
    all,
    searchQuery,
    situation,
    valueMin,
    valueMax,
    createdDateMin,
    createdDateMax,
  ]);

  const sendEmailAndWhatsappButtonCondition = useCallback(
    (id: string) => {
      const row = all.find((obj) => String(obj.id) === id);

      if (!row) return false;

      return nfApiHelper.isAuthorized(row.api_situation);
    },
    [all]
  );

  async function getEmails(nfceId: number) {
    setEmailsHistory([]);
    const emails = await EmailNfService.getAllEmailsByNfId(nfceId, "nfce");
    setEmailsHistory(emails);
  }

  async function getWhatsapps(nfceId: number) {
    setWhatsappHistory([]);
    const history = await WhatsappNfService.getAllWhatsappsByNfId(
      nfceId,
      "nfce"
    );
    setWhatsappHistory(history);
  }

  function handleOpenSendEmailModal(id: string) {
    const row = all.find((obj) => String(obj.id) === id);

    if (!row) return false;

    setShowModalEmail(true);
    getEmails(row.id);
    setInfoEmail({
      refId: String(row.id),
      refName: "nfce",
      toName: "",
      customerName: row.customerName ?? "",
      customerId: String(row.customer),
      email: row.customerEntity.email,
      contactName: row.customerName ?? "",
      message: NfceService.getDefaultEmailMessage(),
      emailSubject: "Comprovante fiscal",
    });
  }

  const handleClickSendEmail = useCallback(async () => {
    try {
      const emails = infoEmail.email.split(";").map((email) => email.trim());

      const row = all.find((obj) => obj.id === Number(infoEmail.refId));

      if (!row) return false;

      await EmailNfService.sendEmail(emails, {
        nf: row,
        type: "nfce",
        customerId: infoEmail.customerId,
        customerName: infoEmail.customerName,
        contactName: infoEmail.contactName,
        message: infoEmail.message,
        refName: infoEmail.refName,
        subject: infoEmail.emailSubject,
      });

      setShowModalSuccess(true);
    } catch (error) {
      setMsgInfo(error.response?.data?.message ?? "Falha ao enviar o e-mail!");
      setShowModalInfo(true);
    }

    setShowModalEmail(false);
  }, [infoEmail, all]);

  function handleOpenSendWhatsappModal(id: string) {
    const row = all.find((obj) => String(obj.id) === id);

    if (!row) return false;

    setShowModalWhatsapp(true);
    getWhatsapps(row.id);
    setInfoWhatsapp({
      refId: String(row.id),
      refName: "nfce",
      customerName: row.customerName ?? "",
      customerId: String(row.customer),
      number: row.customerEntity ? row.customerEntity.cell ?? "" : "",
      message: NfceService.getDefaultWhatsappMessage(),
    });
  }

  const handleClickSendWhatsapp = useCallback(async () => {
    try {
      const row = all.find((obj) => obj.id === Number(infoWhatsapp.refId));

      if (!row) return;

      await WhatsappNfService.sendWhatsapp(infoWhatsapp.number, {
        nf: row,
        type: "nfce",
        customerId: infoWhatsapp.customerId,
        customerName: infoWhatsapp.customerName,
        message: infoWhatsapp.message,
        refName: infoWhatsapp.refName,
      });

      setShowModalSuccess(true);
    } catch (error) {
      setMsgInfo("Falha ao enviar a mensagem!");
      setShowModalInfo(true);
    }

    setShowModalWhatsapp(false);
  }, [infoWhatsapp, all]);

  async function checkLimits() {
    const response = await api.get(
      "companySubscriptionPlans/limits/byField?field=nfce"
    );
    const limits = response.data;
    if (limits.max !== -1 && limits.current >= limits.max) {
      setShowModalLimit(true);
      setModalLimitMsg([
        `O limite para emissões de notas fiscais do consumidor por período foi atingido! (limite: ${limits.max})`,
      ]);
      return false;
    }

    return true;
  }

  return (
    <div className="card card-body pt-4 newProductWrapper">
      <ModalSuccess
        setShowModal={setShowModalSuccess}
        showModal={showModalSuccess}
        msgModal={messageSuccess}
      />
      <ModalLimit
        showModalLimit={showModalLimit}
        setShowModalLimit={setShowModalLimit}
        messages={modalLimitMsg}
      />
      <ModalSendEmail
        showModal={showModalEmail}
        setShowModal={setShowModalEmail}
        onClickSend={handleClickSendEmail}
        emailsHistory={emailsHistory}
        infoEmail={infoEmail}
        setInfoEmail={setInfoEmail}
      />
      <ModalSendWhatsapp
        showModal={showModalWhatsapp}
        setShowModal={setShowModalWhatsapp}
        onClickSend={handleClickSendWhatsapp}
        whatsappHistory={whatsappHistory}
        infoWhatsapp={infoWhatsapp}
        setInfoWhatsapp={setInfoWhatsapp}
      />

      <Modal
        show={showModalInfo}
        onHide={() => setShowModalInfo(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>{msgInfo}</b>
          {!!issueErrors && issueErrors.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="default">
                                        <strong>Código</strong>
                                    </TableCell> */}
                  <TableCell padding="default">
                    <strong>Erro</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issueErrors.map((error, index) => (
                  <TableRow key={index}>
                    {/* <TableCell>
                                            {nfeDetails.api_last_response['body']['status_sefaz']}
                                        </TableCell> */}
                    <TableCell>
                      <span className="text-danger">{error.mensagem}</span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalInfo(!showModalInfo)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Emitir */}
      <ModalValidateNf
        nf={nfceToIssue}
        nfType="nfce"
        showModal={showModalIssue}
        setShowModal={setShowModalIssue}
        onConfirmIssue={handleConfirmIssue}
        onCloseModal={() => setNfceToIssue(undefined)}
      />

      {/* Modal detalhes da nota emitida */}
      <ModalNfDetails
        showModalDetails={showModalDetails}
        setShowModalDetails={setShowModalDetails}
        nfceDetails={nfceDetails}
        cancelNote={cancelNote}
        setCancelNote={setCancelNote}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        cancelError={cancelError}
        onConfirmCancel={handleConfirmCancel}
      />

      <div className="row d-flex align-items-center">
        <div className="col-lg-5 mt-3">
          {user.isAccountant == "n" ? (
            <Button
              type="button"
              variant="success"
              className="mr-2"
              onClick={() => handleClickAdd()}
            >
              Adicionar Nota Fiscal de Consumidor
            </Button>
          ) : (
            <Button
              type="button"
              variant="primary"
              className="mr-2"
              onClick={() => displaySentNfce(sentNfce ? 0 : 1)}
            >
              {sentNfce ? "Exibir todas as notas" : "Exibir notas recebidas"}
            </Button>
          )}
        </div>
        <div className="col-lg-4 mt-3">
          {user.isAccountant == "n" ? (
            <Button
              type="button"
              variant="primary"
              className="mr-2"
              onClick={() => setShowNfeModal(!showNfeModal)}
            >
              Enviar notas para contabilidade
            </Button>
          ) : (
            <></>
          )}
        </div>
        <div className="col-lg-3 mt-3">
          <Search
            query={searchQuery}
            setQuery={setSearchQuery}
            setCollapseAdvancedSearch={setAdvancedSearch}
            onClickSearch={handleClickSearch}
          />
        </div>
      </div>
      <Collapse in={advancedSearch}>
        <div className="row d-flex align-items-center">
          <div className="col-lg-2">
            <TextField
              select
              size="small"
              label="Situação"
              margin="normal"
              variant="outlined"
              value={situation}
            >
              <MenuItem key="0" value="" onClick={() => setSituation("")}>
                Nenhum
              </MenuItem>
              <MenuItem
                key="1"
                value="processando_autorizacao"
                onClick={() => setSituation("processando_autorizacao")}
              >
                Em andamento
              </MenuItem>
              <MenuItem
                key="2"
                value="autorizado"
                onClick={() => setSituation("autorizado")}
              >
                Autorizado
              </MenuItem>
              <MenuItem
                key="3"
                value="erro_autorizacao"
                onClick={() => setSituation("erro_autorizacao")}
              >
                Erro de autorização
              </MenuItem>
              <MenuItem
                key="4"
                value="cancelado"
                onClick={() => setSituation("cancelado")}
              >
                Cancelado
              </MenuItem>
              <MenuItem
                key="5"
                value="erro_api"
                onClick={() => setSituation("erro_api")}
              >
                Denegado
              </MenuItem>
              <MenuItem
                key="6"
                value="aguardando"
                onClick={() => setSituation("aguardando")}
              >
                Aguardando
              </MenuItem>
            </TextField>
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor"
              startAdornment="DE"
              value={valueMin}
              onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor"
              startAdornment="ATÉ"
              value={valueMax}
              onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data criação"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">DE</InputAdornment>
                ),
              }}
              value={createdDateMin}
              onChange={(e) => setCreatedDateMin(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data criação"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">ATÉ</InputAdornment>
                ),
              }}
              value={createdDateMax}
              onChange={(e) => setCreatedDateMax(e.target.value)}
            />
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button onClick={handleClickSearch} className="mr-3">
              Pesquisar
            </Button>

            <Button onClick={clearSearch}>Limpar</Button>
          </div>
        </div>
      </Collapse>

      <div className="mt-3">
        <ListWithModalChangeSituation
          invoice
          headData={headData}
          bodyData={bodyData}
          onEdit={handleClickEdit}
          onClone={handleClickClone}
          onDelete={handleClickDelete}
          customButtons={
            user.isAccountant == "n"
              ? [
                  {
                    class: "btn-light-info",
                    content: <i className="p-0 flaticon-upload"></i>,
                    variant: BsVariant.INFO,
                    popup: "Emitir nota fiscal",
                    onClick: handleClickIssue,
                    showCondition: issueButtonCondition,
                  },
                  {
                    class: "btn-green p-2 mr-3",
                    content: (
                      <img src="/media/icons/whatsapp.png" alt="Whatsapp" />
                    ),
                    variant: BsVariant.SUCCESS,
                    popup: "Envio de whatsapp",
                    onClick: handleOpenSendWhatsappModal,
                    showCondition: sendEmailAndWhatsappButtonCondition,
                  },
                  {
                    class: "btn-blue p-2 mr-3",
                    content: (
                      <i
                        className="flaticon2-email p-0"
                        style={{ color: "#fff" }}
                      ></i>
                    ),
                    variant: BsVariant.SUCCESS,
                    popup: "Envio de email",
                    onClick: handleOpenSendEmailModal,
                    showCondition: sendEmailAndWhatsappButtonCondition,
                  },
                  {
                    class: "btn-light-info",
                    content: <i className="p-0 flaticon-info"></i>,
                    variant: BsVariant.INFO,
                    popup: "Visualizar nota fiscal",
                    onClick: handleClickDetails,
                    showCondition: detailsButtonCondition,
                  },
                ]
              : [
                  {
                    class: "btn-light-info",
                    content: <i className="p-0 flaticon-info"></i>,
                    variant: BsVariant.INFO,
                    popup: "Visualizar nota fiscal",
                    onClick: handleClickDetails,
                    showCondition: detailsButtonCondition,
                  },
                ]
          }
          sortable={true}
          loadData={loadData}
          totalCount={countTotalNf}
          triggerLoad={triggerLoad}
          setTriggerLoad={setTriggerLoad}
        />
      </div>
      <NfeSelectMonthModal showModal={showNfeModal} nfeType={"nfce"} />
    </div>
  );
}
